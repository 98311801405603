import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import About from "../../components/about";
import DigitalTwin from "../../components/digitaltwin";
import FAQ from "../../components/faq";
import Transform from "../../components/help";
import VideoBanner from "../../components/homepagevideobanner";
import Keyfeature from "../../components/keyfeature";
import Loader from "../../components/loader";
import Range from "../../components/rngecaroul";
import Business from "../../components/unique";
import CustomCarousel from "../../components/testingslider";
const Home = () => {
  const [bgColor, setBgColor] = useState("transparent");

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollPosition = window.scrollY;
  //     const windowHeight = window.innerHeight;
  //     const documentHeight = document.documentElement.scrollHeight;

  //     const scrollPercentage =
  //       (scrollPosition / (documentHeight - windowHeight)) * 100;

  //     const colors = [
  //       "#222222",
  //       "#d7d7d7",
  //       "#fff",
  //       "transparent",
  //       "#fff",
  //       "transparent",
  //       "transparent",
  //       "transparent",
  //     ];

  //     const colorIndex = Math.floor((scrollPercentage / 100) * colors.length);

  //     setBgColor(colors[colorIndex]);
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [2000]);

  return (
    <Box
      sx={{
        backgroundColor: bgColor,
        transition: "background-color 0.5s ease",
      }}
    >
      <Box>
        <Loader />
      </Box>
      <Box id="section1">
        <VideoBanner />
      </Box>
      <Box id="section2">
        <CustomCarousel />
      </Box>
      {/* <Box id="section2">
        <Range />
      </Box> */}
      <Box id="section3">
        <DigitalTwin />
      </Box>
      <Box id="section4">
        <Keyfeature />
      </Box>
      <Box id="section5">
        <Transform />
      </Box>
      <Box id="section6">
        <About />
      </Box>
      {/* <Box id="section7">
        <Business />
      </Box> */}
      <Box id="section8">
        <FAQ />
      </Box>
    </Box>
  );
};
export default Home;
