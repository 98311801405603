import { Container, Grid, Typography } from "@mui/material";
import Aos from "aos";
import React, { useEffect, useRef } from "react";
import data from "../../assests/data/data";
import Game from "../../views/game";
import "./index.css";

const Transform = () => {
  useEffect(() => {
    if (window.innerWidth > 768) {
      Aos.init();
    }
  }, []);

  const parallaxRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrollPosition = window.pageYOffset;
        parallaxRef.current.style.backgroundPositionY = `${
          scrollPosition * 1
        }px`;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="container-fluid bg-black transformsection_height">
        <div
          style={{
            height: "100%",
          }}
        >
          <div className="container transformsection_alignment">
            <div className="mb-5">
              <h1 className="keyfetR text-center">LET REALITY SCALE</h1>
              <h1 className="keyfet text-center mt-3 mb-3">
                Help You
                <span className="mil-thin mx-2 text-white">Transform</span>
              </h1>
              <p className="keyfetE mil-light-soft mil-center text-white">
                how you showcase your products and engage with your customers!
              </p>
            </div>

            <Grid container spacing={2}>
              {data.gameData.map((val, i) => (
                <Grid item lg={4} xs={12} xl={4} key={i}>
                  <Game
                    image={val.image}
                    heading={val.heading}
                    subHeading={val.subHeading}
                    price={val.price}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};
export default Transform;
