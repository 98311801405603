import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import CTA from "../components/cta";
import Footer from "../components/footer";
import Mouse from "../components/mouse";
import Navbar from "../components/navbar";
import Progressbar from "../components/progressbar";
import Blog from "../views/blog";
import BlogDetails from "../views/blogdetails";
import Contactus from "../views/contact";
import DEMOREALITY from "../views/demoreality";
import FeatureDetails from "../views/featuredetails";
import Home from "../views/home";
import Privacy from "../views/privacy";
import Product from "../views/product";
import Productdetails from "../views/productdetails";
import Member from "../views/team_member";
import Terms from "../views/terms";
import Commingsoon from "../views/page";
import Portfolio from "../views/product";
import Portfoliodetails from "../views/productdetails";

const Router = () => {
  const location = useLocation();

  return (
    <>
      {location.pathname !== "/demoreality" && <Navbar />}
      <Mouse />
      <Progressbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/team" element={<Member />} />
        <Route exact path="/contact" element={<Contactus />} />
        <Route exact path="/portfolio" element={<Portfolio />} />
        <Route exact path="/Portfoliodetails" element={<Portfoliodetails />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/demoreality" element={<DEMOREALITY />} />
        <Route exact path="/FeaturesDetails" element={<FeatureDetails />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/blogdetails" element={<BlogDetails />} />
        <Route exact path="/Commingsoon" element={<Commingsoon />} />
      </Routes>

      {/* {location.pathname !== "/demoreality" &&
        location.pathname !== "/blog" && (
          <>
            <CTA />
          </>
        )} */}
      {location.pathname !== "/demoreality" && (
        <>
          <Footer />
        </>
      )}
    </>
  );
};

export default Router;
