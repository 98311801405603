import React from "react";
import ArrowSVG from "../../components/arrow";
import "./index.css";
const Commingsoon = () => {
  return (
    <>
      <div className="mil-content">
        <div id="swupMain" className="mil-main-transition">
          <div className="bg-black mil-dark-bg">
            <div className="mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container commingsoonalignment_section">
                    <ul
                      className="mil-breadcrumbs mil-light mil-mb-60"
                      style={{ padding: "0" }}
                    >
                      <li>
                        <a href="/">Homepage</a>
                      </li>
                      <li>
                        <a href="#">Blog</a>
                      </li>
                    </ul>
                    <h1
                      className="mil-muted mil-mb-60"
                      style={{ color: "#ffd94a" }}
                    >
                      Comming{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        Soon
                      </span>
                    </h1>

                    <a
                      className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById("target-element")
                          .scrollIntoView({ behavior: "smooth" });
                      }}
                    >
                      {/* <span className="text-white">Send Message</span>
                      <ArrowSVG className="mil-arrow" fill="#000000" /> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commingsoon;
