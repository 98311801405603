import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import zoom from "../../assests/images/work/zoom.svg";
import ArrowSVG from "../../components/arrow";
import ProductDetailSwiper from "../../components/swiper";
import ProductDetailsSlider from "../../components/wippers";
import { Typography } from "@mui/material";
const FeatureDetails = () => {
  const location = useLocation();

  console.log("location", location);

  const [pageData, setPageData] = useState(null);
  useEffect(() => {
    if (location.state) {
      setPageData(location.state);
    }
  }, [location.state]);

  console.log("pageData", pageData);

  return (
    <>
      <div className="mil-content">
        <div id="swupMain" className="mil-main-transition">
          <div className="bg-black mil-dark-bg">
            <div className="mil-inner-banner">
              <div className="mi-invert-fix">
                <div className="mil-banner-content mil-up">
                  <div className="mil-animation-frame">
                    <div
                      className="mil-animation mil-position-1 mil-scale"
                      data-value-1="7"
                      data-value-2="1.6"
                      style={{
                        transform: "translate3d(0px, 0px, 0px) scale(2, 2)",
                      }}
                    >
                      <div className="mil-dodecahedron">
                        {[...Array(12)].map((_, index) => (
                          <div key={index} className="mil-pentagon">
                            {[...Array(5)].map((_, index) => (
                              <div key={index}></div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="container">
                    <ul
                      className="mil-breadcrumbs mil-light mil-mb-60"
                      style={{ padding: "0" }}
                    >
                      <li>
                        <a href="/">Homepage</a>
                      </li>

                      <li>
                        <a href="#">Feature Detail's</a>
                      </li>
                    </ul>
                    <h1
                      className="mil-muted mil-mb-60"
                      style={{ color: "#ffd94a" }}
                    >
                      Our{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        Feature
                      </span>
                      <br /> Detail's{" "}
                      <span className="mil-thins" style={{ color: "white" }}>
                        {/* soon */}
                      </span>
                    </h1>
                    <a
                      href="#"
                      className="mil-link mil-accent mil-arrow-place mil-down-arrow"
                    >
                      <span className="text-white">READ MORE</span>
                      <ArrowSVG className=" mil-arrow" fill="#000000" />
                    </a>
                  </div>
                  <div className="backgroundvlack">
                    <section className="mil-p-120-120">
                      <div
                        className="container-fluid mil-image-frame mil-fw mil-up"
                        style={{ transform: "translate(0px, 0px)" }}
                      >
                        <img src={pageData && pageData.image} alt="logo" />
                        <div
                          data-fancybox="gallery"
                          data-no-swup=""
                          className="mil-zoom-btn"
                        >
                          {/* <img src={zoom} alt="zoom" /> */}
                        </div>
                      </div>

                      <div className="container mt-5">
                        {/* <div className="mil-p-120-90"> */}
                        <div className="row justify-content-between">
                          <div className="col-lg-5">
                            <h2 className="mil-up mil-mb-60">
                              {/* {pageData && pageData.productDetails.paraHeading} */}
                            </h2>
                          </div>
                          <div className="col-lg-6 text-white">
                            <p className="mil-up mil-mb-30">
                              {pageData && pageData.heading}
                            </p>
                            <p className="mil-up">
                              {pageData && pageData.subHeading}
                            </p>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeatureDetails;
