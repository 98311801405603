import { Box, Typography } from "@mui/material";
import React from "react";
import team from "../../assests/data/team";
import "./index.css";
import Aos from "aos";

const OfficialTeamMembers = (props) => {
  Aos.init();
  const userProfile = (url) => {
    window.location.href = url;
  };

  return (
    <div className="">
      {props.selectedMemberIndex === 1 && (
        <section
          id="team"
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <div className="container allteamsection_alignment">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                marginBottom: 5,
              }}
            ></Box>
            {team.firstteamMembers.map((member, index) => (
              <Box
                sx={{
                  mb: 5,
                }}
              >
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{ borderRadius: "8px" }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{ my: 1 }}
                      >
                        {member.role}
                      </Typography>
                      <Typography sx={{ mt: 2 }} className="text_font">
                        {member.para1}
                      </Typography>
                      <Typography sx={{ mt: 2 }} className="text_font">
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Box>
            ))}
          </div>
        </section>
      )}
      {props.selectedMemberIndex === 2 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment ">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.secondteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {props.selectedMemberIndex === 5 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.fifthteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {props.selectedMemberIndex === 3 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.thirdteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {props.selectedMemberIndex === 4 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.sixthteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {props.selectedMemberIndex === 6 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.fourthteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}{" "}
      {props.selectedMemberIndex === 7 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.seventhteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {props.selectedMemberIndex === 8 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.eighthteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 3,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
      {props.selectedMemberIndex === 9 ? (
        <div
          data-aos="fade-up"
          data-aos-easing="ease-in-out"
          data-aos-duration="1000"
        >
          <section id="team">
            <div className="container allteamsection_alignment">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  marginBottom: 5,
                }}
              ></Box>
              {team.ninthteamMembers.map((member, index) => (
                <div className="row" key={index}>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="mil-team-card mil-up mil-mb-30">
                      <img
                        src={member.image}
                        alt="Team member"
                        style={{
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  </div>{" "}
                  <div className="col-sm-6 col-md-8 col-lg-8 mb-5">
                    <Box
                      sx={{
                        display: "grid",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: 6,
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize={"40px"}
                        fontWeight={600}
                      >
                        {member.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={"20px"}
                        sx={{
                          my: 1,
                        }}
                      >
                        {member.role}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para1}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                        }}
                        className="text_font"
                      >
                        {member.para2}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <ul className="mil-social-icons mil-left">
                          {member.socialMedia.map((val, id) => {
                            return (
                              <li onClick={() => userProfile(val.url)} key={id}>
                                {val.icon}
                              </li>
                            );
                          })}
                        </ul>
                      </Box>
                    </Box>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default OfficialTeamMembers;
