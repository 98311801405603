import { Box, Typography } from "@mui/material";
import React, { useState, useRef } from "react";
import team from "../../assests/data/team";
import ArrowSVG from "../../components/arrow";
import OfficialTeamMembers from "../../components/teammembers";
import "./index.css";

const Member = () => {
  const [selectedMemberIndex, setSelectedMemberIndex] = useState(0);
  const teamSectionRef = useRef(null);

  const selectedTab = (val) => {
    setSelectedMemberIndex(val);
  };

  const handleBackClick = () => {
    setSelectedMemberIndex(0);
  };

  const scrollToTeamSection = () => {
    if (teamSectionRef.current) {
      teamSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <>
      <div className="mil-content">
        <div id="swupMain" className="mil-main-transition" data-swup="0">
          <div className="mil-inner-banner">
            <div
              className="mil-banner-content mil-up"
              style={{
                rotate: "none",
                scale: "none",
                transform: "translate(0px, 0px)",
                opacity: 1,
                translate: "none",
              }}
            >
              <div className="mil-animation-frame">
                <div
                  className="mil-animation mil-position-4 mil-dark mil-scale"
                  data-value-1="6"
                  data-value-2="1.4"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                    WebkitTransform:
                      "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                  }}
                >
                  <div className="mil-dodecahedron">
                    {[...Array(12)].map((_, index) => (
                      <div key={index} className="mil-pentagon">
                        {[...Array(5)].map((_, index) => (
                          <div key={index}></div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="container section_alignment">
                <ul
                  className="mil-breadcrumbs mil-mb-60"
                  style={{ padding: "0" }}
                >
                  <li>
                    <a href="/" className="section_head">
                      Homepage
                    </a>
                  </li>
                  <li>
                    <a href="/team" className="section_head">
                      Team
                    </a>
                  </li>
                </ul>
                <h1 className="mil-mb-60" style={{ padding: "0" }}>
                  Meet <span className="mil-thin">Our</span>
                  <br /> Creative <span className="mil-thin">Team</span>
                </h1>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    {selectedMemberIndex === 0 ? (
                      <div
                        className="mil-link mil-dark mil-arrow-place mil-down-arrow"
                        onClick={scrollToTeamSection}
                      >
                        <span className="section_head">Our team</span>
                        <ArrowSVG className="mil-arrow" fill="#000000" />
                      </div>
                    ) : (
                      <div
                        className="mil-link mil-dark mil-arrow-place mil-down-arrow"
                        onClick={handleBackClick}
                      >
                        <span className="section_head">Our team</span>
                        <ArrowSVG
                          className="mil-arrow arrow_side "
                          fill="#000000"
                        />
                      </div>
                    )}
                  </Box>

                  <Box>
                    {selectedMemberIndex === 0 ? (
                      ""
                    ) : (
                      <div
                        className="mil-link mil-dark mil-arrow-place mil-down-arrow"
                        onClick={handleBackClick}
                      >
                        <span className="section_head">Back</span>
                      </div>
                    )}
                  </Box>
                </Box>
              </div>
            </div>
          </div>

          <div className="team_membersection teamsection_alignment">
            {selectedMemberIndex === 0 && (
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <div className="container">
                  <Typography
                    fontSize={"16px"}
                    fontWeight={300}
                    letterSpacing={0.5}
                    color={"#000"}
                    className="team_text"
                  >
                    Founded in 2024, Reality Scale emerged from a shared vision
                    among industry experts: to create transformative digital
                    solutions that revolutionise how businesses operate and
                    engage with their audiences. Our innovative products and
                    solutions have earned the trust of renowned global brands,
                    including Disney, Lexus, Olympic Association, Honda,
                    Renault, Hyundai, and many others.
                  </Typography>

                  <Box>
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={19}
                        fontWeight={500}
                        className="team_textheading"
                      >
                        At Reality Scale, we specialise in delivering
                        state-of-the-art solutions across diverse sectors:
                      </Typography>
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <ul className="team_text">
                          <li>
                            Automotive configurations: As a leading configurator
                            provider, we offer the most advanced configuration
                            systems to major automotive brands like Honda,
                            Hyundai, and Renault.
                          </li>
                          <li className="mt-2">
                            Interior and real estate visualisation: Proprietary
                            tech for high-quality real-time and V-Ray-like
                            rendering, setting new standards in digital property
                            showcasing.
                          </li>
                          <li className="mt-2">
                            Web3 and blockchain applications: Developers of
                            enterprise blockchain solutions and engaging web3
                            games, pushing decentralised technology boundaries.
                          </li>
                          <li className="mt-2">
                            Gaming, VR, and AR: Crafting immersive experiences
                            that redefine user engagement in entertainment,
                            education, and professional training.
                          </li>
                          <li className="mt-2">
                            Health tech innovations: Leveraging technology to
                            enhance patient care, streamline processes, and
                            advance healthcare research.
                          </li>
                          <li className="mt-2">
                            AI-driven systems: Creating intelligent digital
                            twins and LLM-powered AI applications that transform
                            business operations and decision-making.
                          </li>
                        </ul>
                      </Box>
                    </Box>{" "}
                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={19}
                        fontWeight={500}
                        className="team_textheading"
                      >
                        The power of Reality Scale lies in our unique blend of
                        talent:
                      </Typography>
                      <Box
                        sx={{
                          mt: 2,
                        }}
                      >
                        <ul className="team_text">
                          <li>
                            Visionary technologists versed in AI, cloud
                            computing, and real-time rendering
                          </li>
                          <li className="mt-2">
                            Seasoned business strategists with deep market
                            insights
                          </li>
                          <li className="mt-2">
                            Creative minds expert in 3D visualization and user
                            experience design
                          </li>
                          <li className="mt-2">
                            Skilled communicators adept at translating complex
                            solutions into compelling narratives
                          </li>
                        </ul>
                      </Box>
                    </Box>{" "}
                    <Box
                      sx={{
                        mt: 3,
                      }}
                    >
                      <Typography
                        variant="h6"
                        color={"#000"}
                        fontSize={17}
                        fontWeight={400}
                        className="team_text"
                      >
                        This diversity of expertise allows us to approach
                        challenges from multiple angles, crafting innovative
                        solutions that were previously unattainable.
                        <br /> We're not just a sum of our parts - we're a
                        multiplication of our collective knowledge and skills.
                      </Typography>
                    </Box>
                  </Box>
                </div>
              </Box>
            )}
          </div>

          <Box
            ref={teamSectionRef}
            sx={{
              mt: 10,
            }}
          >
            {selectedMemberIndex === 0 && (
              <section id="team">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-1"></div>
                    {team.teamMembers.map((member, index) => (
                      <div
                        className="col-sm-12 col-md-3 col-lg-2"
                        key={index}
                        onClick={() => selectedTab(member.id)}
                      >
                        <Box>
                          <div className="image-container">
                            <img
                              src={member.image}
                              alt="Team member"
                              className="team_image"
                            />
                            <div className="overlay"></div>
                          </div>
                        </Box>
                        <Box
                          sx={{
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            marginBottom: 3,
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h2"
                              color={"#000"}
                              fontSize={{ xs: 17, sm: 15, lg: 15 }}
                              fontWeight={400}
                            >
                              {member.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontSize={"14px"}
                              fontWeight={400}
                            >
                              {member.role}
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    ))}
                    <div className="col-sm-1"></div>
                  </div>
                </div>
              </section>
            )}{" "}
          </Box>
          <Box
            sx={{
              my: 6,
            }}
          >
            {selectedMemberIndex === 0 && (
              <section id="team">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-2"></div>
                    {team.teamMember.map((member, index) => (
                      <div
                        className="col-sm-12 col-md-3 col-lg-2 p-3"
                        key={index}
                        onClick={() => selectedTab(member.id)}
                      >
                        <Box>
                          <div className="image-container">
                            <img
                              src={member.image}
                              alt="Team member"
                              className="team_image"
                            />
                            <div className="overlay"></div>
                          </div>
                        </Box>
                        <Box
                          sx={{
                            display: "grid",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            marginBottom: 3,
                          }}
                        >
                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h2"
                              color={"#000"}
                              fontSize={{ xs: 17, sm: 15, lg: 15 }}
                              fontWeight={400}
                            >
                              {member.name}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "grid",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant="h4"
                              fontSize={"14px"}
                              fontWeight={400}
                            >
                              {member.role}
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    ))}
                    <div className="col-sm-2"></div>
                  </div>
                </div>
              </section>
            )}
          </Box>

          <div className="team_detailssection">
            <OfficialTeamMembers selectedMemberIndex={selectedMemberIndex} />
          </div>

          <section
            className="mil-soft-bg"
            style={{
              paddingTop: "2rem",
              paddingBottom: "3rem",
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-10">
                  <span
                    className="mil-suptitle mil-suptitle-right mil-suptitle-dark mil-up"
                    style={{
                      rotate: "none",
                      scale: "none",
                      transform: "translate(0px, 40px)",
                      opacity: 1,
                      translate: "none",
                    }}
                  >
                    Looking to make your mark? We'll help you turn <br /> your
                    project into a success story.
                  </span>
                </div>
              </div>
              <div className="mil-center">
                <h2
                  className="mil-up mil-mb-60"
                  style={{
                    rotate: "none",
                    scale: "none",
                    transform: "translate(0px, 40px)",
                    opacity: 1,
                    translate: "none",
                  }}
                >
                  Ready to bring your <span className="mil-thin">ideas to</span>{" "}
                  life? <br /> We're{" "}
                  <span className="mil-thin">here to help</span>
                </h2>{" "}
                <div
                  className="mil-up mil-mb-60"
                  style={{
                    rotate: "none",
                    scale: "none",
                    transform: "translate(0px, 40px)",
                    opacity: 1,
                    translate: "none",
                  }}
                >
                  <a href="/contact" className="mil-button mil-arrow-place">
                    <span>Contact us</span>
                    <ArrowSVG className="mil-arrow" fill="#000000" />
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Member;
