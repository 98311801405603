import memberimage from "../../assests/images/teamimages/Srikant_Dash.jpg";
import kuldeepsingh from "../../assests/images/teamimages/kuldeepsingh.jpeg";
import prashant from "../../assests/images/teamimages/Prashant_Gyan.jpg";
import atul from "../../assests/images/teamimages/atulsingh.jpeg";
import piyush from "../../assests/images/teamimages/piyush.jpeg";
import steffan from "../../assests/images/teamimages/steefen.jpg";
import { FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa6";
import rohit from "../../assests/images/teamimages/rohit.jpg";
import savita from "../../assests/images/teamimages/Savita_Singh.jpg";
import Azim from "../../assests/images/teamimages/Azim_Akram.jpg";

let team = {
  teamMembers: [
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },

    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
    {
      id: 4,
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
    {
      id: 5,
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
  ],

  teamMember: [
    {
      id: 6,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
    {
      id: 7,
      name: "Rohit Kumar",
      role: "Dev Lead",
      image: rohit,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
    {
      id: 8,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Suspendisse potenti. Sed leo elit, mollis sed felis sed, vehicula blandit nulla. Sed eget velit diam. Praesent sed viverra nulla. Sed vehicula odio in luctus pellentesque. Suspendisse sollicitudin sit amet ipsum a placerat. Nam scelerisque posuere dui ac ultrices.",
      para2:
        "Suspendisse potenti. Sed leo elit, mollis sed felis sed, vehicula blandit nulla. Sed eget velit diam. Praesent sed viverra nulla. Sed vehicula odio in luctus pellentesque. Suspendisse sollicitudin sit amet ipsum a placerat. Nam scelerisque posuere dui ac ultrices.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
    {
      id: 9,
      name: "Savita Singh",
      role: "Human Resource",
      image: savita,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: {
        instagram: "#",
        youtube: "#",
        twitter: "#",
        facebook: "#",
      },
    },
  ],

  firstteamMembers: [
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
  ],

  secondteamMembers: [
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
  ],

  thirdteamMembers: [
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
  ],

  fourthteamMembers: [
    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
  ],
  fifthteamMembers: [
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },

    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
  ],
  sixthteamMembers: [
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
  ],
  seventhteamMembers: [
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
  ],
  eighthteamMembers: [
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
    {
      id: 8,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },

    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
  ],
  ninthteamMembers: [
    {
      id: 9,
      name: "Savita Singh",
      role: "Humar Resource",
      image: savita,
      para1:
        "Savita Singh is the Head of the Human Resources Department at Reality Scale with diverse experience in the telecom, insurance, manufacturing, advertising, and IT sectors. She has a proven track record of setting up and managing end-to-end HR operations. Savita is a strong and effective HR professional with hands-on experience in HR operations, talent management, employee engagement, performance management, grievance handling, HR budgeting, HR policy formulation, compensation and benefits management, and statutory compliances.",
      para2:
        "Her role encompasses personnel and HR administration, recruitment, employee grievance resolution, payroll and reconciliation, daily administration, client management, and large fund management. Savita excels in coordinating with all departments and managing internal and external audits. She empowers leaders by providing a unique perspective and appreciates that human capital is every organization’s greatest asset, contributing to value-added goals.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/savita-singh-899936132/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 9,
      name: "Azim Akram",
      role: "Creative Head",
      image: Azim,
      para1:
        "Azim Akram, the Head of Design at Reality Scale, blends artistic flair with strategic vision. With expertise in branding, motion graphics, video editing, 3D animation, web design, and more, Azim crafts compelling visual narratives.",
      para2:
        "Leading national workshops and creative ventures, and drawing on his rich experience as an artist and designer, Azim merges traditional art forms with cutting-edge digital techniques to deliver innovative solutions. At Reality Scale, he drives projects that captivate audiences and align with the company’s strategic goals. His creativity and visionary approach are key to the company's growth and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/azim-akram-00b7ba16b/",
        },
      ],
    },
    {
      name: "Rohit Kumar",
      role: "Team Lead",
      image: rohit,
      para1:
        "Rohit Kumar is a proficient Node.js Developer with extensive experience in developing robust and scalable backend solutions. Currently contributing his expertise at Digixito Media Pvt. Ltd., Rohit excels in creating and managing Restful APIs using Node.js, Express.js, and TypeScript. He has a strong background in MongoDB, MySQL, and Redis, ensuring efficient database management.",
      para2:
        " Known for his analytical problem-solving skills and dedication to high-quality software development, Rohit effectively collaborates with teams to deliver innovative solutions across various projects. His hands-on approach and technical proficiency drive the success of the development lifecycle from concept to deployment.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/rohit-kumar-098217160/",
        },
      ],
    },
    {
      name: "Piyush Chachondhia",
      role: "Co-Founder",
      image: piyush,
      para1:
        "Piyush Chachondhia, Head of Business Development and co-founder of Reality Scale team, brings a wealth of diverse entrepreneurial experience to drive the company's growth and innovation. With a background spanning marine engineering, agri-tech, and software development, Piyush leverages his multifaceted expertise to spearhead Reality Scale's sales, marketing, and business development initiatives.",
      para2:
        "As a serial entrepreneur with a track record of creating successful ventures, Piyush excels at identifying new business opportunities and leading expansion efforts. His strategic insights and innovative approach to sales and marketing strategies are instrumental in propelling Reality Scale's market presence. Piyush's experience in resource management and overall company growth initiatives positions him uniquely to contribute to Reality Scale's scaling efforts and long-term success.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 1,
      name: "Srikant Dash",
      role: "Chief Executive Officer",
      image: memberimage,
      para1:
        "Srikant Dash, founder and CEO of Reality Scale, is a visionary technology leader with  experience across multiple domains, including automotive, architectural, AI, healthcare, and gaming sectors. With a proven track record of architecting scalable systems and managing complex projects, Srikant specialises in crafting innovative solutions that give companies a competitive edge.",
      para2:
        "His approach involves reimagining existing and cutting-edge technologies and combining them uniquely to address industry-specific challenges. His background as a CTO and technology consultant for various companies has equipped him with the perfect blend of technical knowledge and business acumen to drive Reality Scale's mission.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin color="#000" />,
          url: "https://www.linkedin.com/in/piyushchachondhia/",
        },
      ],
    },
    {
      id: 3,
      name: "Kuldeep Singh Awana",
      role: "Chief Operating Officer",
      image: kuldeepsingh,
      para1:
        "Kuldeep Singh Awana, COO and co-founder of Reality Scale, is a skilled 3D visualization expert and operations leader with extensive experience in automotive, architectural, and gaming sectors. Applying over a decade of expertise from digital art to advanced Unreal Engine development, Kuldeep excels at integrating cutting-edge technical solutions with business strategies, enhancing client satisfaction and company growth.",
      para2:
        "Adept at managing diverse teams and complex projects, Kuldeep ensures efficient delivery of high-quality results. His comprehensive understanding of creative and technical aspects uniquely positions him to drive Reality Scale's operational excellence and innovation in digital visualisation.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/kuldeep-awana-6a446b148/?originalSubdomain=in",
        },
      ],
    },
    {
      id: 2,
      name: "Steffen Marx",
      role: "Chief Technology Officer",
      image: steffan,
      para1:
        "Steffen Marx, Chief Technology Officer of Reality Scale, brings a wealth of expertise in software development, which he has cultivated since his youth. His diverse career spans health tech, gaming, real-time communication, and full-stack development, encompassing projects of all scales. Steffen has developed a comprehensive skill set and is proficient in real-time rendering and low-latency streaming applications.",
      para2:
        "As CTO, Steffen's technical prowess drives Reality Scale's innovation. His deep understanding of low-level systems and high-level applications has been crucial in developing the company's groundbreaking technologies. Steffen's ability to tackle complex technical challenges and pioneering work form the backbone of Reality Scale's technological edge, cementing the company's position as a leader in digital twin and real-time solutions.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/srikant-dash-a040ba1ba/?trk=people-guest_people_search-card&originalSubdomain=in",
        },
      ],
    },

    {
      id: 4,
      name: "Atul Singh",
      role: "Sales Manager",
      image: atul,
      para1:
        "Atul Surendra Singh, Head of Sales and co-founder of Reality Scale, brings a wealth of expertise in virtual resourcing, cloud infrastructure, digital media, and eCommerce sales. With a proven track record in driving business growth and managing complex projects, Atul excels in developing and executing strategic sales initiatives that enhance client engagement and company revenue.",
      para2:
        "At Reality Scale, Atul leverages his comprehensive understanding of digital solutions and client relationship management to lead the company's sales efforts. His ability to bridge complex technological solutions with client needs uniquely positions him to drive Reality Scale's sales processes. Atul's innovative sales and marketing strategies are instrumental in expanding the company's market presence and boosting conversion rates.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/atulsurendrasingh/",
        },
      ],
    },
    {
      name: "Prashant Gyan",
      role: "Co-Founder",
      image: prashant,
      para1:
        "Prashant Gyan, co-founder and Strategy Officer of Reality Scale, brings the team a powerful combination of technical expertise and business acumen. With a background spanning cloud security, AgriTech, and marine engineering, Prashant's diverse experience fuels his talent for crafting innovative business strategies. His entrepreneurial journey across multiple successful ventures showcases his ability to transform cutting-edge ideas into thriving businesses.",
      para2:
        "At Reality Scale, Prashant spearheads the development and execution of strategic initiatives, ensuring the company's technological innovations align with market needs and business goals. His proficiency in integrating advanced technologies with sound business strategies and his visionary approach drive the company's growth, operational excellence, and market leadership.",
      socialMedia: [
        {
          icon: <AiFillInstagram color="#000" />,
          url: "#",
        },
        {
          icon: <FaYoutube color="#000" />,
          url: "#",
        },
        {
          icon: <FaTwitter color="#000" />,
          url: "#",
        },
        {
          icon: <FaFacebook color="#000" />,
          url: "#",
        },
        {
          icon: <FaLinkedin />,
          url: "https://www.linkedin.com/in/prashantgyan/",
        },
      ],
    },
  ],
};

export default team;
