import arrow from "../images/svg/arrow.png";
let unique = {
  head1: "  Branding and Identity Design",
  para1:
    "Our creative agency is a team of professionals focused on helping your brand grow.",
  img1: arrow,

  head2: "  Branding and Identity Design",
  para2:
    "Our creative agency is a team of professionals focused on helping your brand grow.",
  img2: arrow,

  head3: "  Branding and Identity Design",
  para3:
    "Our creative agency is a team of professionals focused on helping your brand grow.",
  img3: arrow,

  head4: "  Branding and Identity Design",
  para4:
    "Our creative agency is a team of professionals focused on helping your brand grow.",
  img4: arrow,
};
export default unique;
