import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import data from "../../assests/data/data";
import "./index.css";
const BlogDetails = () => {
  return (
    <>
      <div className="mil-content">
        <div id="swupMain" className="mil-main-transition" data-swup="0">
          <div className="mil-inner-banner">
            <div
              className="mil-banner-content mil-up"
              style={{
                rotate: "none",
                scale: "none",
                transform: "translate(0px, 0px)",
                opacity: 1,
                translate: "none",
              }}
            >
              <div className="mil-animation-frame">
                <div
                  className="mil-animation mil-position-4 mil-dark mil-scale"
                  data-value-1="6"
                  data-value-2="1.4"
                  style={{
                    transform:
                      "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                    WebkitTransform:
                      "translate3d(0px, 0px, 0px) scale(1.9613, 1.9613)",
                  }}
                >
                  <div className="mil-dodecahedron">
                    {[...Array(12)].map((_, index) => (
                      <div key={index} className="mil-pentagon">
                        {[...Array(5)].map((_, index) => (
                          <div key={index}></div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="container">
                <ul
                  className="mil-breadcrumbs mil-mb-60"
                  style={{ padding: "0" }}
                >
                  <li>
                    <a href="/">Homepage</a>
                  </li>
                  <li>
                    <a href="/blog">Blog</a>
                  </li>{" "}
                  <li>
                    <a href="/blogdetails">Blog Details</a>
                  </li>
                </ul>
                <h1 className="mil-mb-60" style={{ padding: "0" }}>
                  Exploring <span className="mil-thin">the world</span>
                  <br /> Through our <span className="mil-thin">Blog</span>
                </h1>
              </div>
            </div>
          </div>

          <Container>
            <Box
              sx={{
                borderBottom: "2px solid #d3d3d3",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {data.blogdetailsdata.map((val, id) => {
                    return (
                      <img
                        key={id}
                        src={val.img}
                        style={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    );
                  })}
                </Grid>
              </Grid>
              <Box
                sx={{
                  my: "2rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* <Typography>CATEGORY: TECHNOLOGY</Typography> */}
                <Typography>DATE: APRIL 2024</Typography>
                <Typography>AUTHOR: PAUL TRUEMAN</Typography>
              </Box>
            </Box>
          </Container>

          <Container
            sx={{
              marginBottom: "3%",
              marginTop: "5%",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography fontWeight={300} variant="h6" color={"#000"}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel est
                </Typography>
              </Grid>
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography
                  fontWeight={300}
                  variant="h6"
                  color={"#000"}
                  sx={{
                    backgroundColor: "#d3d3d3",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderLeft: "5px solid #000",
                    p: { xs: 2, sm: 8, md: 10, lg: 10 },
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
              </Grid>
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography
                  variant="h4"
                  color={"#000"}
                  fontSize={20}
                  marginBottom={1.5}
                >
                  Understand the field
                </Typography>
                <Typography color={"grey"} variant="body1" fontWeight={300}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel estMaecenas posuere in metus eu consequat.
                  Cras vitae mi a diam hendrerit molestie. Maecenas eget urna
                  non tellus placerat condimentum ut vel est
                </Typography>
              </Grid>
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography
                  variant="h4"
                  color={"#000"}
                  fontSize={20}
                  marginBottom={1.5}
                >
                  Understand the field
                </Typography>
                <Typography color={"grey"} variant="body1" fontWeight={300}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel estMaecenas posuere in metus eu consequat.
                  Cras vitae mi a diam hendrerit molestie. Maecenas eget urna
                  non tellus placerat condimentum ut vel est
                </Typography>
              </Grid>
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography
                  variant="h4"
                  color={"#000"}
                  fontSize={20}
                  marginBottom={1.5}
                >
                  Understand the field
                </Typography>
                <Typography color={"grey"} variant="body1" fontWeight={300}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel estMaecenas posuere in metus eu consequat.
                  Cras vitae mi a diam hendrerit molestie. Maecenas eget urna
                  non tellus placerat condimentum ut vel est
                </Typography>
              </Grid>
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography
                  variant="h4"
                  color={"#000"}
                  fontSize={20}
                  marginBottom={1.5}
                >
                  Understand the field
                </Typography>
                <Typography color={"grey"} variant="body1" fontWeight={300}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel estMaecenas posuere in metus eu consequat.
                  Cras vitae mi a diam hendrerit molestie. Maecenas eget urna
                  non tellus placerat condimentum ut vel est
                </Typography>
              </Grid>
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <div className="row">
                  {data.blogdummydetailsdata.map((val, id) => {
                    return (
                      <div className="col-sm-6 blogimg_placement">
                        <Box>
                          <img
                            src={val.img}
                            style={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </Box>
                      </div>
                    );
                  })}
                </div>
                <Typography
                  color={"grey"}
                  variant="body1"
                  fontWeight={300}
                  marginTop={3}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel estMaecenas posuere in metus eu consequat.
                  Cras vitae mi a diam hendrerit molestie. Maecenas eget urna
                  non tellus placerat condimentum ut vel est
                </Typography>{" "}
              </Grid>
              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography
                  fontWeight={300}
                  variant="h6"
                  color={"#000"}
                  sx={{
                    backgroundColor: "#d3d3d3",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderLeft: "5px solid #000",
                    p: { xs: 2, sm: 8, md: 10, lg: 10 },
                  }}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
              </Grid>

              <Grid item xs={{ sm: 12, md: 8, lg: 8 }} marginTop={3}>
                <Typography
                  color={"grey"}
                  variant="body1"
                  fontWeight={300}
                  marginTop={3}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel estMaecenas posuere in metus eu consequat.
                  Cras vitae mi a diam hendrerit molestie. Maecenas eget urna
                  non tellus placerat condimentum ut vel est
                </Typography>{" "}
                <Typography
                  color={"grey"}
                  variant="body1"
                  fontWeight={300}
                  marginTop={3}
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
                  commodo vel justo ullamcorper porttitor. Nunc vitae ipsum non
                  mi dignissim pretium. Cras a interdum risus, in tempor ex.
                  Maecenas posuere in metus eu consequat. Cras vitae mi a diam
                  hendrerit molestie. Maecenas eget urna non tellus placerat
                  condimentum ut vel estMaecenas posuere in metus eu consequat.
                  Cras vitae mi a diam hendrerit molestie. Maecenas eget urna
                  non tellus placerat condimentum ut vel est
                </Typography>
              </Grid>
            </Grid>
          </Container>

          <section
            className="mil-soft-bg"
            style={{
              marginTop: "4rem",
            }}
          >
            <div className="container mil-p-120-120">
              <div className="row mil-services-grid">
                {data.blogdata.map((val, index) => (
                  <div
                    key={index}
                    className="col-md-6 col-lg-6 blog_placement "
                  >
                    <a href="/blogdetails">
                      <img
                        src={val.img}
                        style={{
                          width: "100%",
                          height: "70%",
                        }}
                      />
                    </a>

                    <Box
                      sx={{
                        mt: 2,
                      }}
                    >
                      <Typography
                        variant="h1"
                        color={"#000"}
                        sx={{
                          fontSize: "30px",
                          fontWeight: 600,
                        }}
                      >
                        {val.head}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 2,
                          color: "grey",
                          fontSize: "16px",
                          fontWeight: 300,
                        }}
                        variant="h6"
                      >
                        {val.para}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        mt: 5,
                      }}
                    >
                      <a href="/blogdetails">
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#000",
                          }}
                        >
                          Read more <FaArrowRightLong className="ms-2" />
                        </span>
                      </a>
                    </Box>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
