import featureimage4 from "../../assests/images/Features/feature3.webp";
import featureimage3 from "../../assests/images/Features/feature4.webp";
import featureimage1 from "../../assests/images/Features/feature1.webp";
import featureimage5 from "../../assests/images/Features/feature5.webp";
import featureimage2 from "../../assests/images/Features/feature2.webp";

let featureData = {
  dataType: [
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
  ],
  feature1: [
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
  ],
  feature2: [
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },

    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
  ],
  feature4: [
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },

    {
      // heading: "Content Creation Suite",
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
  ],
  feature5: [
    {
      id: 1,
      heading: "Cloud-Native ",
      head: "Platform",
      image: featureimage1,
      heading2: "Limitless Reach, Unmatched Agility",
      title: "Global Accessibility :",
      sub_title: "Access products from anywhere, enhancing convenience.",
      title2: "Scalability and Flexibility :",
      sub_title2:
        "Quickly adapt to market changes without additional infrastructure.",
    },
    {
      id: 3,
      heading: "Business ",
      head: "Accelerator",
      image: featureimage3,
      heading2: "Enhance Efficiency, Maximize Impact",
      title: "Efficient Operations :",
      sub_title:
        "Streamline sales and marketing with impactful remote presentations.",
      title2: "Strategic Insights:",
      sub_title2: "Utilize analytics to refine strategies and boost ROI.",
    },
    {
      id: 5,
      heading: "Content ",
      head: "Creation",
      image: featureimage5,
      heading2: " Craft Compelling Experiences",
      title: "Streamlined Content Production :",
      sub_title: "Simplify digital content creation to save time and costs.",
      title2: "Marketing Autonomy :",
      sub_title2:
        "Quickly update marketing materials in-house, keeping campaigns effective and relevant.",
    },
    {
      id: 2,
      heading: "Reality ",
      head: "Modeling",
      image: featureimage2,
      heading2: "See Your Vision in Stunning Detail",
      title: "Enhanced Customer Engagement :",
      sub_title: "Captivate audiences with photorealistic 3D visualizations.",
      title2: "Improved Product Understanding :",
      sub_title2: "Explore products in detail beyond physical limitations.",
    },

    {
      id: 4,
      heading: "Adaptive ",
      head: "AI",
      image: featureimage4,

      heading2: " Intelligent Interactions, Personalized Experiences",
      title: "24/7 Customer Assistance :",
      sub_title: "Offer constant AI-powered customer support.",
      title2: "Tailored Customer Interactions :",
      sub_title2:
        "Personalize experiences to enhance satisfaction and conversions.",
    },
  ],
};

export default featureData;
