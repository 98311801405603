import { Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link, useNavigate } from "react-router-dom";
import image from "../../assests/carimages/disney_image1.webp";
import image1 from "../../assests/carimages/tatanexon.webp";
import ArrowSVG from "../../components/arrow";
import "./index.css";

const CustomCarousel = () => {
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const tabData = [
    {
      label: "VR/AR",
      slides: [
        {
          id: 0,
          img: image,
          thumbnail: image,
          title: "VR/AR",
          description: "Description for VR/AR Image 1",
          content: "Disney Pyjama Ride",
          heading: "Disney Pyjama Ride",
          subheading:
            "Rhythm-based VR game synchronized with live music. Uses MIDI integration for real-time note mapping, creating an interactive concert experience in virtual reality.",
        },
        {
          id: 1,
          img: image1,
          thumbnail: image1,
          title: "VR/AR",
          description: "Description for VR/AR Image 2",
          content: "Youth Olympics Project",
          heading: "Youth Olympics Project",
          subheading:
            "India's first VR car configurator. Offers real-time customization of vehicle features, colors, and accessories in a fully immersive virtual environment.",
        },
      ],
    },
    {
      label: "Automotive",
      slides: [
        {
          id: 0,
          img: image,
          thumbnail: image,
          title: "Automotive",
          description: "Description for Automotive Image 1",
          content: "Tata Nexon",
          heading: "Tata Nexon",
          subheading:
            "MR experience for auto shows. Overlays virtual features on a physical car, allowing exploration of internal components and hidden features through a headset.",
        },
        {
          id: 1,
          img: image1,
          thumbnail: image1,
          title: "Automotive ",
          description: "Description for Automotive Image 2",
          content: "Honda Showroom Configurator",
          heading: "Honda Showroom Configurator",
          subheading:
            "Showroom-based configurator displaying cars on a 60-inch screen. Unique feature allows sales consultants to control the display using their mobile devices, enhancing the customer experience.",
        },
      ],
    },
    {
      label: "Architectural",
      slides: [
        {
          id: 0,
          img: "",
          thumbnail: image,
          title: "Architectural",
          description: "Description for Architectural Image 1",
          content: "Content",
          heading: "",
          commingsoon: "Comming Soon",
        },
        {
          id: 1,
          img: "",
          thumbnail: image1,
          title: "Architectural",
          description: "Description for Architectural Image 2",
          content: "Content",
          heading: "",
          commingsoon: "Comming Soon",
        },
      ],
    },
    {
      label: "Gaming",

      slides: [
        {
          id: 0,
          img: "",
          thumbnail: image,
          title: "Gaming ",
          description: "Description for Gaming Image 1",
          content: "Content",
          heading: "",
          commingsoon: "Comming Soon",
        },
        {
          id: 1,
          img: "",
          thumbnail: image1,
          title: "Gaming ",
          description: "Description for Gaming Image 2",
          content: "Content",
          heading: "",
          commingsoon: "Comming Soon",
        },
      ],
    },
  ];

  const handleThumbnailClick = (index) => {
    setSelectedSlide(index);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSelectedSlide(0);
  };

  let navigate = useNavigate();
  const handleClick = () => {
    navigate("/portfolio");
  };

  return (
    <>
      <div className=" bg-black ">
        <div className="container pt-4 section_sizing ">
          <div className="section_height">
            <div className="main_head">
              <h1 className="keyfetR text-center">SHOWCASE</h1>
              <h1 className="keyfet text-center mt-3 mb-5">
                {" "}
                Every Detail
                <span className="mil-thin mx-2 text-white">Counts</span>
              </h1>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                {/* <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  sx={{
                    marginLeft: 5,
                    width: "fit-content",
                    borderRadius: "6px",
                  }}
                >
                  {tabData.map((val, id) => (
                    <Tab
                      key={id}
                      label={val.label}
                      sx={{
                        color: "#fff",
                        padding: "10px 40px",
                        fontSize: "12px",
                        fontWeight: 600,
                      }}
                    />
                  ))}
                </Tabs> */}
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  sx={{
                    ".MuiTabs-indicator": {
                      backgroundColor: "#ffd94a",
                    },
                    paddingLeft: 4,
                  }}
                >
                  {tabData.map((val, id) => (
                    <Tab
                      key={id}
                      label={val.label}
                      sx={{
                        color: activeTab === id ? "#ffd94a" : "grey",
                        fontSize: "12px",
                        fontWeight: 600,
                        padding: "10px 20px",
                        borderBottom:
                          activeTab === id ? "2px solid #ffd94a" : "none",
                        "&.Mui-selected": {
                          color: "#ffd94a",
                        },
                      }}
                    />
                  ))}
                </Tabs>
              </div>
              <div
                style={{
                  cursor: "pointer",
                }}
              >
                <a href="/portfolio">
                  <Typography
                    color={"#ffd94a"}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Show more <IoIosArrowForward />
                  </Typography>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-10 col-md-10 col-lg-10">
                <div className="slider_alignment">
                  <Carousel
                    selectedItem={selectedSlide}
                    onChange={(index) => setSelectedSlide(index)}
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    interval={3000}
                    showArrows={false}
                    swipeable={true}
                    stopOnHover={true}
                  >
                    {tabData[activeTab].slides.map((slide) => (
                      <div
                        key={slide.id}
                        className="carousel-slide"
                        style={{
                          backgroundImage: `url(${slide.img})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          // height: "570px",
                          borderRadius: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h1">
                            {slide.commingsoon}
                          </Typography>
                        </div>
                        <div className="slider_text">
                          <Typography
                            color={"#ffd94a"}
                            variant="h4"
                            fontSize={30}
                            fontWeight={600}
                            textAlign={"start"}
                          >
                            {slide.heading}
                          </Typography>
                          <Typography
                            color={"#fff"}
                            variant="h6"
                            fontSize={15}
                            fontWeight={300}
                            textAlign={"start"}
                            marginTop={"5px"}
                          >
                            {slide.subheading}
                          </Typography>
                          <Link
                            onClick={handleClick}
                            className="mil-links mil-dark mil-arrow-place mil-up text-start mt-4 "
                          >
                            <a href="/portfolio">
                              <span className="mil-label mil-upper mil-accent text-grey">
                                Show more
                              </span>
                              <ArrowSVG className="mil-arrow" />
                            </a>
                          </Link>
                        </div>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 ">
                <div
                  style={{
                    marginTop: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                  className="slider_thumbnail"
                >
                  {tabData[activeTab].slides.map((val, id) => (
                    <div
                      key={id}
                      onClick={() => handleThumbnailClick(id)}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "10px",
                        cursor: "pointer",
                        border:
                          selectedSlide === id ? "2px solid #fff" : "none",
                        padding: "10px",
                      }}
                    >
                      <div>
                        <img
                          src={val.thumbnail}
                          height={"80px"}
                          width={"70px"}
                          alt={val.title}
                          style={{
                            borderRadius: "6px",
                          }}
                        />
                      </div>
                      <div className="ms-2">
                        <Typography
                          color={"#fff"}
                          fontSize={"13px"}
                          fontWeight={400}
                        >
                          {val.content}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomCarousel;
