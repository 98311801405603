import { Box, Typography } from "@mui/material";
import Aos from "aos";
import React from "react";
import image1 from "../../assests/images/digital_twinimage.webp";
import MilLinesSVG from "../../components/aboutsvg";
import "./index.css";
const DigitalTwin = () => {
  Aos.init();
  return (
    <div className="container container_alignment">
      <div className="row">
        <div className="col-sm-5">
          <img src={image1} height={"100%"} width={"100%"} />
        </div>
        <div className="col-sm-7 p-0 m-0">
          <div className="text_coloum text-grid align-items-center justify-content-center ">
            <h1 className="mb-0 main_heading">What is a</h1>
            <h1 className="sub_heading">Digital Twin?</h1>
            <p className="mt-3 section_text mb-2">
              A digital twin is a technology concept of a virtual environment,
              in simpler terms, it is a digital replica or model of an object in
              the physical world. This twin, or digital counterpart, runs across
              all interactions and processes of the said object, using real-time
              data from the physical world to update itself. It optimizes based
              on simulations, supplemented by machine learning, to help make
              decisions, monitor, and predict the performance of physical assets
              in real-time.{" "}
            </p>
            <Typography
              variant="h1"
              color={"#000"}
              fontSize={20}
              fontWeight={600}
              className="digital_twinhead"
            >
              How does a digital twin work?
            </Typography>
            <p className="section_text mb-0 mt-2">
              Let’s understand Data Assimilation. It is the process of
              integrating newly received data and observations from a subject
              with a forecasted model. Subsequently, the forecasted model is
              updated with the received data. This continual flow of obtained
              data, periodically updating the model is called Data assimilation.
              This approach is fundamental to how digital twins operate. The
              exchange of data takes place in real-time via IOT (Internet of
              Things), connecting the model (digital twin) to the physical
              asset.
            </p>
            <p className="section_text mb-0">
              Meaning- your digital twin is not static but a dynamic and
              evolving entity, personalized to the parameters of the physical
              asset.
            </p>
            <p className="section_text">
              The rendition of the digital twin varies and depends on the
              intended use. It can be used to optimize operations across all
              domains; to predict outcomes with “what if” simulations,
              operational management, fault classification etc.
            </p>
            {/* <p
              className="fw-400 section_text"
              style={{
                marginTop: "8px",
              }}
            >
              Let’s understand Data Assimilation. It is the process of
              integrating newly received data and observations from a subject
              with a forecasted model. Subsequently, the forecasted model is
              updated with the received data. This continual flow of obtained
              data, periodically updating the model is called Data assimilation.
              This approach is fundamental to how digital twins operate. The
              exchange of data takes place in real-time via IOT (Internet of
              Things), connecting the model (digital twin) to the physical
              asset.
            </p>{" "}
            <p
              className="fw-400 section_text"
              style={{
                marginTop: "5px",
              }}
            >
              Meaning- your digital twin is not static but a dynamic and
              evolving entity, personalized to the parameters of the physical
              asset.
            </p>{" "}
            <p
              className="fw-400 section_text"
              style={{
                marginTop: "5px",
              }}
            >
              The rendition of the digital twin varies and depends on the
              intended use. It can be used to optimize operations across all
              domains; to predict outcomes with “what if” simulations,
              operational management, fault classification etc.
            </p>{" "}
            <p
              className="fw-400 section_text"
              style={{
                marginTop: "5px",
              }}
            >
              The ultimate objective is to obtain valuable knowledge that can be
              used to improve the original physical entity.
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalTwin;
