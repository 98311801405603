import React, { useEffect } from "react";
import "./index.css";
import image1 from "../../assests/images/Welcome to Reality Scale/Welcome_to_Reality_Scale.webp";
import Aos from "aos";
import MilLinesSVG from "../../components/aboutsvg";
import { aboutfile } from "../../assests/data/componentdata";
const About = () => {
  Aos.init();
  return (
    <div className="container mil-p-120-30 aboutsection_alignment" id="about">
      <div className="row justify-content-between align-items-center">
        <div
          className="col-lg-6 col-xl-5 mil-mb-90 text_alignment"
          data-aos="fade-down"
          data-aos-offset="20"
          data-aos-delay="100"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="true"
          data-aos-anchor-placement="top"
        >
          <h1 className="mb-0 main_heading">
            Welcome
            <br />
          </h1>
          <h1 className="sub_heading">
            <span
              style={{
                color: "#ffd94a",
                fontSize: "54px",
                fontWeight: 600,
              }}
            >
              to{" "}
            </span>
            Reality Scale
          </h1>
          <p className="fw-300 text_font">{aboutfile.subtitle}</p>
          {aboutfile.paragraphs.map((paragraph, index) => (
            <p key={index} className="mil-up mil-mb-10 ">
              <span className="section_text">
                {paragraph.data}
                <b>{paragraph.text}</b>
                <b>{paragraph.bold}</b> {paragraph.text1}
                <b>{paragraph.bold1}</b>
                {paragraph.text2}
                <b>{paragraph.bold2}</b>
                {paragraph.text3}
              </span>
            </p>
          ))}
        </div>
        <div
          className="col-lg-5"
          data-aos="fade-right"
          data-aos-offset="20"
          data-aos-delay="100"
          data-aos-duration="800"
          data-aos-easing="ease-in-out"
          data-aos-mirror="false"
          data-aos-once="false"
          data-aos-anchor-placement="top"
        >
          <div className="mil-about-photo mil-mb-90">
            <div className="mil-lines-place">
              <MilLinesSVG />
            </div>
            <div
              className="mil-up mil-img-frame"
              style={{ paddingBottom: "160%" }}
            >
              <img
                src={image1}
                alt="logo"
                className="mil-scale"
                data-value-1="1"
                data-value-2="1.2"
                style={{
                  transform: "translate3d(0px, 0px, 0px) scale(1.1353, 1.1353)",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
