import React from "react";
import data from "../../assests/data/data";
import ArrowSVG from "../arrow";
import "./index.css";
import navlogo from "../../assests/navlogo_icon.png";
import { Box, Grid } from "@mui/material";
const Footer = () => {
  return (
    <footer className="mil-dark-back">
      <div className="mi-inverter-fix">
        <div className="container mil-p-120-30 footersection_alignment">
          <div className="row justify-content-between">
            <div className="col-md-4 col-lg-4 mil-mb-60 text_alignment">
              <div className="main-footer__about">
                <p className="footer-widget__text fw-400">
                  Let’s start working together
                </p>
                <a className="footer_number">+91 8800291352</a>
              </div>
              <div className="mil-muted mil-logo mil-up mil-mb-30 mt-4 ">
                <img src={navlogo} style={{ width: "64%" }} alt="logo" />
              </div>
              <p className="mil-light-soft mil-up mil-mb-30 mt-5 fw-200">
                Subscribe our newsletter:
              </p>
              <form className="mil-subscribe-form mil-up">
                <input type="text" placeholder="Enter our email" />
                <button
                  type="submit"
                  className="mil-button mil-icon-button-sm2 mil-arrow-place"
                >
                  <ArrowSVG className="mil-arrow" fill="#000000" />
                </button>
              </form>
            </div>
            <div className="col-md-7 col-lg-6">
              <div className="row justify-content-end">
                <div className="col-md-6 col-lg-7">
                  <div className="mil-footer-menu mil-mb-30">
                    <ul className="p-0">
                      {data.data.map((val, id) => {
                        return (
                          <span key={id}>
                            <a href={val.path}>
                              <li className="mil-up ">
                                <span>{val.label}</span>
                              </li>
                            </a>
                          </span>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5 fw-200">
                  <ul className="mil-menu-list mil-up mil-mb-30 p-0 fw-200">
                    {data &&
                      data.menuItems &&
                      data.menuItems.map((item, index) => (
                        <li key={index}>
                          <a
                            href={item.href}
                            className="mil-light-soft"
                            style={{ color: item.color }}
                          >
                            {item.text}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* <div className="row justify-content-between flex-sm-row-reverse"> */}
          <div className="row justify-content-between">
            <div className="col-md-4 col-lg-6 mil-mb-20">
              <div className="mil-vert-between text_alignment">
                <div className="mil-mb-30">
                  <ul className="mil-social-icons mil-up">
                    {data.socialIcons.map((social, index) => (
                      <li key={index}>
                        <a
                          href={social.href}
                          target="_blank"
                          className="social-icon"
                        >
                          {social.icon}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <p className="mil-light-soft mil-up fw-200 copywrite">
                  ©Copyright 2024 -
                  <span className="mx-1  text-white">Reality Scale LLC</span>&
                  <a
                    href="https://www.digixito.com/"
                    className="mx-1 text-white"
                  >
                    Digixito Media Private Limited.
                  </a>
                  <br />
                  <span className="text-grey">All Rights Reserved.</span>
                </p>
                <div className="row">
                  {data.links.map((val, id) => {
                    return (
                      <div className="col-md-6 col-lg-4 text-grey" key={id}>
                        <a href={val.url}>
                          <span
                            style={{
                              cursor: "pointer",
                              color: "grey",
                            }}
                          >
                            {val.text}
                          </span>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-7 col-lg-6">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Box>
                    <Box>
                      <h6 className="mil-muted mil-up mil-mb-10 mb-0">
                        India{" "}
                      </h6>
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "12px",
                        }}
                      >
                        (Operation Office)
                      </span>
                    </Box>

                    <p
                      className="mil-light-soft mil-up mt-2"
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      2nd Floor, D-320,
                      <br /> Sector 63, Noida, Uttar Pradesh,
                      <br /> 201307 INDIA
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <h6 className="mil-muted mil-up mil-mb-10 mb-0">USA </h6>
                    <span
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        // margin: "0 5px",
                      }}
                    >
                      (Head Office)
                    </span>
                    <p
                      className="mil-light-soft mil-up mt-2"
                      style={{
                        fontSize: "17px",
                        fontWeight: 700,
                      }}
                    >
                      30 N Gould St <br /> Ste R Sheridan,
                      <br />
                      WY 82801 USA
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box>
                    <h6 className="mil-muted mil-up mil-mb-10 mb-0">India </h6>
                    <span
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                      }}
                    >
                      (Operation Office)
                    </span>

                    <p
                      className="mil-light-soft mil-up mt-2"
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      D-597, Sector-6, C.D.A,
                      <br /> Cuttack, Odisha,
                      <br /> Pin-753014
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
