import image6 from "../../assests/images/work/6.webp";
import image3 from "../../assests/images/work/honda/civics.jpg";
import image5 from "../../assests/images/work/interiro/5.jpg";
import image4 from "../../assests/images/work/merced/logomc.png";
import image10 from "../../assests/images/work/virtual/virtual.png";
import image9 from "../../assests/images/work/wndr/wndr.png";
import image8 from "../../assests/images/work/youth/youthicon.png";
import image1 from "../images/work/mahindra/mahindra.png";
import image2 from "../images/work/mystrim/mystrim.png";
//    import video from "https://www.youtube.com/embed/0qMaplaZIKE?si=TDOUpfVwHoaetdwY";
import unique from "../../assests/data/unique";
import disneyslide2 from "../../assests/images/work/disneyslider/disneyslide2.png";
import inter1 from "../../assests/images/work/honda/diigi.png";
import inter3 from "../../assests/images/work/honda/front.jpg";
import inter from "../../assests/images/work/honda/grey.png";
import Renault from "../../assests/images/work/honda/kwid.png";
import inter2 from "../../assests/images/work/honda/sunroof.jpg";
import mahindra1 from "../../assests/images/work/mahindra/back.jpg";
import mahindra from "../../assests/images/work/mahindra/light.jpg";
import mahindra2 from "../../assests/images/work/mahindra/opengate.jpg";
import mahindra3 from "../../assests/images/work/mahindra/wheel.jpg";
import mercs from "../../assests/images/work/merced/full.jpg";
import mercs3 from "../../assests/images/work/merced/inter.jpg";
import mercs1 from "../../assests/images/work/merced/logomc.png";
import mercs2 from "../../assests/images/work/merced/spec.jpg";
import mystrim from "../../assests/images/work/mystrim/mystrim.png";
import youth from "../../assests/images/work/youth/youthicon.png";
import featureimage4 from "../../assests/images/Features/adaptive.webp";
import featureimage3 from "../../assests/images/Features/business.webp";
import featureimage1 from "../../assests/images/Features/cloud.webp";
import featureimage5 from "../../assests/images/Features/content.webp";
import featureimage2 from "../../assests/images/Features/realit.webp";
import blog1 from "../images/blog1.jpg";
import blog2 from "../images/blog2.jpg";
import blogbanner from "../images/blogbanner.jpg";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import nexon from "../../assests/carimages/tatanexon.webp";
import olympic from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (5).webp";
import olympic1 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (1).webp";
import olympic2 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (2).webp";
import olympic3 from "../../assests/carimages/Olymics Bmx Ar Gameplay-1 (4).webp";
import disneyslide3 from "../../assests/carimages/disney_image1.webp";
import disneyslide4 from "../../assests/carimages/Disney Pijama Ride VR_Game (1).webp";
import disneyslide5 from "../../assests/carimages/Disney Pijama Ride VR_Game (4).webp";
let data = {
  faq: [
    {
      title: "What is Reality Scale? ",
      message:
        "RealityScale is a virtual twin provider, establishing a new-wave of technological solutions and innovative marketing by integrating artificial intelligence and cloud-centric approaches. Helping businesses transform their operations, asset demonstration and customer experience. ",
    },
    {
      title: "How can Reality Scale benefit my business? ",
      message:
        "Our solutions hinge on flexibility and personalization, enabling businesses to optimize and elevate product marketing and demonstration to unprecedented levels. Leveraging our cloud-based digital twin technology and toolkits, you can provide customers with detailed, interactive, hyper-realistic product experiences—from the comfort of their homes and on any device. Reaching a Global-Audience with cost-efficiency.",
    },
    {
      title: "What industries does Reality Scale serve? ",
      message:
        "Our solutions hinge on flexibility and personalization, enabling businesses to optimize and elevate product marketing and demonstration to unprecedented levels. Leveraging our cloud-based digital twin technology and toolkits, you can provide customers with detailed, interactive, hyper-realistic product experiences—from the comfort of their homes and on any device. Reaching a Global-Audience with cost-efficiency.",
    },
    {
      title: "What sets Reality Scale apart?  ",
      message:
        "We don't just create 3D virtual models or parallels; we build intelligent, interactive experiences and highly accessible assets that continually evolve with your chosen business parameters and streamline operations across the domains of e-commerce, supplemented by powerful AI-driven analytics and actionable insights. We have meticulously curated a tri-model technology, integrating Digital Twins with cloud-centered and machine learning approaches, setting us apart.",
    },
    {
      title: "Can Reality Scale develop custom applications for my business? ",
      message:
        "Starting with us is simple! Contact us today and schedule a demo. Where you can explore how our technology works through a demonstration. Further discuss how it can suitably be applied to your specific business needs. From there, you can receive a customized plan for your business to bring your assets into the smartness of the digital realm.",
    },
  ],
  gameData: [
    {
      image: "https://reality-scale-buck.s3.amazonaws.com/AI-Integration.jpg",
      heading: "AI Integration",
      subHeading:
        "Access automated customer service with ai-powered interactions and support.",
    },
    {
      image:
        "https://reality-scale-buck.s3.amazonaws.com/Automotive-Solutions.webp",
      heading: "Automotive Solutions",
      subHeading:
        "Exhibit vehicles meticulously and achieve a global presence through virtual showrooms and interactive configurators.",
    },

    {
      image: "https://reality-scale-buck.s3.amazonaws.com/Web3-Frontier.webp",
      heading: "Web3 Frontier ",
      subHeading:
        "Indulge into the future of digital innovation with our Metaverse, BlockChain and NFT resources.",
    },

    {
      image:
        "https://reality-scale-buck.s3.amazonaws.com/Real-Estate-Solutions.webp",
      heading: "Real Estate Solutions ",
      subHeading:
        "Revolutionize property tours with immersive 3D visualizations and Ai-driven marketing solutions.",
    },
    {
      image: "https://reality-scale-buck.s3.amazonaws.com/XR-Immersion.webp",
      heading: "XR Immersion",
      subHeading:
        "Explore the newer dimensions of visual experience with our interactive, augmented and virtual reality solutions.",
    },
    {
      image:
        "https://reality-scale-buck.s3.amazonaws.com/reality-scale-framework.webp",
      heading: "Reality Scale Framework ",
      subHeading:
        "Create anywhere-accessible, hyper-realistic cloud presentations enabled by our development toolkit.  ",
    },
  ],
  blogPosts: [
    {
      category: "TECHNOLOGY",
      date: "May 24, 2024",
      title: "How to Become a Graphic Designer in 10 Simple Steps",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius sequi commodi dignissimos optio, beatae, eos necessitatibus nisi. Nam cupiditate consectetur nostrum qui! Repellat natus nulla, nisi aliquid, asperiores impedit tempora sequi est reprehenderit cumque explicabo, dicta. Rem nihil ullam totam ea voluptas quibusdam repudiandae id ut at iure! Totam, a!",
    },
    {
      category: "TECHNOLOGY",
      date: "May 24, 2024",
      title: "How to Become a Graphic Designer in 10 Simple Steps",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius sequi commodi dignissimos optio, beatae, eos necessitatibus nisi. Nam cupiditate consectetur nostrum qui! Repellat natus nulla, nisi aliquid, asperiores impedit tempora sequi est reprehenderit cumque explicabo, dicta. Rem nihil ullam totam ea voluptas quibusdam repudiandae id ut at iure! Totam, a!",
    },
  ],
  testimonial: [
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
    {
      testimonialheading: "Emma Trueman",
      testimonialsubheading: "Envato market",
      testimonialparagraph:
        "   I had the pleasure of working with this creative agency, and I must say, they truly impressed me. They consistently think outside the box, resulting in impressive and impactful work. I highly recommend this agency for their consistent delivery of exceptional creative solutions.",
    },
  ],

  projects: ["VR/AR", "AUTOMOTIVE", "ARCHITECTURAL", "GAMING"],

  usefulLinks: [
    { name: "Privacy Policy", url: "/" },
    { name: "Terms and conditions", url: "/" },
    "Cookie Policy",
    "Careers",
  ],
  product: [
    {
      name: "Disney Pyjama Ride",
      type: "VR/AR",
      img: disneyslide2,
      productDetails: {
        bannerImage: disneyslide2,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide3,
          },
          {
            img: disneyslide4,
          },
          {
            img: disneyslide5,
          },
        ],
        paraHeading: "Disney Pyjama Ride",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Youth Olympics Project",
      type: "VR/AR",
      img: olympic,
      productDetails: {
        bannerImage: olympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "Youth Olympics Project",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Tata Nexon",
      type: "VR/AR",
      img: nexon,
      productDetails: {
        bannerImage: nexon,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
        paraHeading: "Tata Nexon",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    // {
    //   name: "Youth Olympics Project",
    //   type: "VR/AR",
    //   img: image2,
    //   productDetails: {
    //     bannerImage: image2,
    //     client: "ENVATO MARKET",
    //     brand: "Mysterium  Game",
    //     slider1Image: [
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //     ],
    //     paraHeading: "Mysterium VR Game",
    //     para1:
    //       "MIDI assets were used to set up live music syncing, enabling the game and effects to occur in time with live music. A completely immersive experience is created when players hit musical notes while enjoying live music and it has been designed using an unreal engine. It is developed and designed the level structure and gaming mechanics. To make the game enticing, emphasis on sound effects, lighting, and animation has been laid. ",
    //     para2:
    //       "The game has been optimized to operate seamlessly on virtual reality headsets. It has been made sure that the game was engaging yet challenging to play. To sync the game and live music, it has integrated with MIDI tools.",
    //     slider2: [
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "We Are VR Virtual Digital Cone",
    //   type: "AUTOMOTIVE",
    //   img: image4,
    //   productDetails: {
    //     bannerImage: image4,
    //     client: "ENVATO MARKET",
    //     brand: "Mercedes",
    //     slider1Image: [
    //       {
    //         img: mercs,
    //       },
    //       {
    //         img: mercs1,
    //       },
    //       {
    //         img: mercs2,
    //       },
    //       {
    //         img: mercs3,
    //       },
    //     ],
    //     paraHeading: "Mercedes Maybach",
    //     para1:
    //       "Mercedes Maybach Meta 2 demo created a mixed reality (MR) exhibit an Indian automotive show. It enabled visitors to see a Mercedes-Maybach's features in mixed reality by placing virtual elements over the real-world car. Installed a system that lets the user explore the MR cross-sections and characteristics of the car. It aimed to achieve a seamless experience by bringing the virtual and real-world cars into alignment. Also, it developed animations and visual effects to present the car's attributes in an appealing and dynamic manner.  ",
    //     para2:
    //       "It also optimizes the system performance on mixed-reality devices. Mercedes Maybach Meta 2 demo aimed to improve the User Interface's usability &intuitiveness, and tried to replicate as much of the real car's lighting and textures as possible in the virtual model. Generally, project management includes resources, execution and planning.",
    //     slider2: [
    //       {
    //         img: mercs,
    //       },
    //       {
    //         img: mercs1,
    //       },
    //       {
    //         img: mercs2,
    //       },
    //       {
    //         img: mercs3,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Mahindra VR Configurator",
    //   type: "AUTOMOTIVE",
    //   img: image3,
    //   productDetails: {
    //     bannerImage: image3,
    //     client: "Artificial Reality",
    //     brand: "Mahindra",
    //     slider1Image: [
    //       {
    //         img: inter,
    //       },
    //       {
    //         img: inter1,
    //       },
    //       {
    //         img: inter2,
    //       },
    //       {
    //         img: inter3,
    //       },
    //     ],
    //     paraHeading: "MAHINDRA",
    //     para1:
    //       "Manindra VR car configurator is India's first car configurator. The goal of this creative tool is to make the process of exposure to an automobile easier. Customers can discover the vehicle of their dreams in an authentic environment. Real-time color adjustments, feature additions, and accessory trials are all possible. This makes the vehicle experience more dynamic and interesting. They may see their dream car take shape with the VR car configurator. They have the ability to alter the outside color, choose internal features. At the dealership, it also saves time",
    //     para2:
    //       "For automobile buyers, the virtual reality car configurator is revolutionary. It enables them to create their ideal car from every perspective. They can compare, test drive, and configure several vehicles in this virtual environment. ",
    //     slider2: [
    //       {
    //         img: inter,
    //       },
    //       {
    //         img: inter1,
    //       },
    //       {
    //         img: inter2,
    //       },
    //       {
    //         img: inter3,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Mysterium VR Game",
    //   type: "     ARCHITECTURAL",
    //   img: image5,
    //   productDetails: {
    //     bannerImage: image5,
    //     client: "ENVATO MARKET",
    //     brand: "Reality Scale Interior",
    //     slider1Image: [
    //       {
    //         img: image5,
    //       },
    //       {
    //         img: image5,
    //       },
    //       {
    //         img: image5,
    //       },
    //       {
    //         img: image5,
    //       },
    //     ],
    //     paraHeading: "Reality Scale Interior",
    //     para1:
    //       "A home surveillance camera that pays great attention to security and user privacy, featuring two modes to provide security while protecting personal privacy. The camera has an open and closed mode, we define the product to have clear two sides, expressing two working states and emotions.",
    //     para2:
    //       "Presents a simple and quiet state when not in use, delivering a gentle and security. At the same time, the camera can adapt to a variety of environments, providing elegant ways of wall hanging and standing installation.",
    //     slider2: [
    //       {
    //         img: image5,
    //       },
    //       {
    //         img: image5,
    //       },
    //       {
    //         img: image5,
    //       },
    //       {
    //         img: image5,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Mercedes Maybach Meta 2 Demo",
    //   type: "ARCHITECTURAL",
    //   img: image6,
    //   productDetails: {
    //     bannerImage: image6,
    //     client: "ENVATO MARKET",
    //     brand: "Fashion Project",
    //     slider1Image: [
    //       {
    //         img: image6,
    //       },
    //       {
    //         img: image6,
    //       },
    //       {
    //         img: image6,
    //       },
    //       {
    //         img: image6,
    //       },
    //     ],
    //     paraHeading: "Simplicity, elegance, innovation",
    //     para1:
    //       "A home surveillance camera that pays great attention to security and user privacy, featuring two modes to provide security while protecting personal privacy. The camera has an open and closed mode, we define the product to have clear two sides, expressing two working states and emotions.",
    //     para2:
    //       "Presents a simple and quiet state when not in use, delivering a gentle and security. At the same time, the camera can adapt to a variety of environments, providing elegant ways of wall hanging and standing installation.",
    //     slider2: [
    //       {
    //         img: image6,
    //       },
    //       {
    //         img: image6,
    //       },
    //       {
    //         img: image6,
    //       },
    //       {
    //         img: image6,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Honda Showroom Configurator",
    //   type: "     GAMING",
    //   img: disneyslide2,
    //   productDetails: {
    //     bannerImage: disneyslide2,
    //     client: "ENVATO MARKET",
    //     brand: "Disney Game ",
    //     slider1Image: [
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //     ],
    //     paraHeading: "Disney Game Project",
    //     para1:
    //       "The project developed a multiplayer VR game based on the Unreasl engine. The project management consists of planning, resources, and execution. The game has been examined and developed to offer a flawless experience to the players and viewers. To make it more alluring it has been designed to work on the animation and sound effects. Based on logic and physics, it is designed in a way that is enjoyable and full of challenges.  ",
    //     para2:
    //       "Moreover, it functions on the user considering the interaction and creates a friendly environment for the child. The game ensures the safety of the child and follows the stipulated relevant regulatory standards. The designed game is supposed to be broadcast live during a Disney children's show. Stipulation of the crucial and innovative technology has been done for live broadcasting of the gameplay of the show.",
    //     slider2: [
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Cloud-Streamed Automotive Configurators",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "WNDR Platform",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },

    // {
    //   name: "LEXUS Project",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Real-Estate project",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },

    // {
    //   name: "Scene Craft",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Fashion Digital Twin",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },

    // {
    //   name: "Ship",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Factory",
    //   type: "GAMING",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. ",
    //     para2:
    //       "In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid. The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
  ],

  vr: [
    {
      name: "Disney Pyjama Ride",
      type: "VR/AR",
      img: disneyslide2,
      productDetails: {
        bannerImage: disneyslide2,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide3,
          },
          {
            img: disneyslide4,
          },
          {
            img: disneyslide5,
          },
        ],
        paraHeading: "Disney Pyjama Ride",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Youth Olympics Project",
      type: "VR/AR",
      img: olympic,
      productDetails: {
        bannerImage: olympic,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: olympic,
          },
          {
            img: olympic1,
          },
          {
            img: olympic2,
          },
          {
            img: olympic3,
          },
        ],
        paraHeading: "Youth Olympics Project",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    // {
    //   name: "Mahindra VR Configurator",
    //   type: "VR/AR",
    //   img: image1,
    //   productDetails: {
    //     bannerImage: image1,
    //     client: "ENVATO MARKET",
    //     brand: "Mahindra",
    //     slider1Image: [
    //       {
    //         img: mahindra,
    //       },
    //       {
    //         img: mahindra1,
    //       },
    //       {
    //         img: mahindra2,
    //       },
    //       {
    //         img: mahindra3,
    //       },
    //     ],
    //     paraHeading: "Mahindra VR Configurator!",
    //     para1:
    //       "Designed a virtual reality automobile configurator with Unreal Engine. enabled users to customize a car in virtual reality by altering colors, models, and accessories. Launched a very thorough 3D model of the car, enabling viewers to explore the outside and inside in virtual reality. As the users will open the door, they may experience the complete model. To make the virtual car seem genuine, work was done on its physics and animation. This user-friendly interface is created to make it simple for users to personalize their virtual reality car.  ",
    //     para2:
    //       "To smooth the performance of VR headsets, the system has been optimized. It enables users to compare, save, and share different setups and also ensures that the final model meets all the standards, for example, accessibility. Data collection was added to the system so that the user's information and the car configuration they browsed were stored. In addition, it gives the client the option to analyze the usage metric. The car could be viewed from various angles by the user.",
    //     slider2: [
    //       {
    //         img: mahindra,
    //       },
    //       {
    //         img: mahindra1,
    //       },
    //       {
    //         img: mahindra2,
    //       },
    //       {
    //         img: mahindra3,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Mysterium VR Game",
    //   type: "VR/AR",
    //   img: image2,
    //   productDetails: {
    //     bannerImage: image2,
    //     client: "ENVATO MARKET",
    //     brand: "Mysterium VR",
    //     slider1Image: [
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //     ],
    //     paraHeading: "Mysterium VR Game",
    //     para1:
    //       "MIDI assets were used to set up live music syncing, enabling the game and effects to occur in time with live music. A completely immersive experience is created when players hit musical notes while enjoying live music and it has been designed using an unreal engine. It is developed and designed the level structure and gaming mechanics. To make the game enticing, emphasis on sound effects, lighting, and animation has been laid. ",
    //     para2:
    //       "The game has been optimized to operate seamlessly on virtual reality headsets. It has been made sure that the game was engaging yet challenging to play. To sync the game and live music, it has integrated with MIDI tools.",
    //     slider2: [
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //       {
    //         img: mystrim,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },

    // {
    //   name: "WNDR Platform",
    //   type: "VR/AR",
    //   img: image9,
    //   productDetails: {
    //     bannerImage: image9,
    //     client: "ENVATO MARKET",
    //     brand: "WNDR ",
    //     slider1Image: [
    //       {
    //         img: image9,
    //       },
    //       {
    //         img: image9,
    //       },
    //       {
    //         img: image9,
    //       },
    //       {
    //         img: image9,
    //       },
    //     ],
    //     paraHeading: "WNDR Platform!",
    //     para1:
    //       "It worked on cloud streaming-based old-fashioned flash games like Project. Working on the gameplay and modifying it to perform on the cloud.  Smooth gameplay and animations are designed. It worked on creating realistic-looking metahumans. I",
    //     para2:
    //       " It has integrated non-fungible token (NFT) features into the platform, like as NFT marketplace and used NFT to elevate the gameplay. It has enhanced the user experience to run smoothly across a range of devices. Its working is based on user interface and user experience.",
    //     slider2: [
    //       {
    //         img: image9,
    //       },
    //       {
    //         img: image9,
    //       },
    //       {
    //         img: image9,
    //       },
    //       {
    //         img: image9,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Virtual Digital Cone",
    //   type: "         VR/AR",
    //   img: image10,
    //   productDetails: {
    //     bannerImage: image10,
    //     client: "ENVATO MARKET",
    //     brand: "Virtual Digital Cone",
    //     slider1Image: [
    //       {
    //         img: image10,
    //       },
    //       {
    //         img: image10,
    //       },
    //       {
    //         img: image10,
    //       },
    //       {
    //         img: image10,
    //       },
    //     ],
    //     paraHeading: "Virtual Digital Cone",
    //     para1:
    //       "Designed a scanning process for virtual property creation with Unreal Engine.  An immersive system has been developed using WebGL to transfer Unreal scenes for web display. A variety of properties has been created in VR, such as buildings, interiors and outer environments. Several cinematic videos showcasing the virtual properties were produced. It created a system that exports unreal scenes automatically into lightweight, that can be available on all platforms. ",
    //     para2:
    //       "It worked on user Interface to make it more user-friendly. It focuses on streamlining the exporting and scanning procedures to minimize file sizes and boost efficiency. To make it more realistic it worked on the physics and lighting of the scene.  It makes sure the final output works with a variety of devices and platforms. All things considered, project management involves resources, planning and enforcement.",
    //     slider2: [
    //       {
    //         img: image10,
    //       },
    //       {
    //         img: image10,
    //       },
    //       {
    //         img: image10,
    //       },
    //       {
    //         img: image10,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Disney Game Project",
    //   type: "VR/AR",
    //   img: disneyslide2,
    //   productDetails: {
    //     bannerImage: disneyslide2,
    //     client: "ENVATO MARKET",
    //     brand: "Disney Game",
    //     slider1Image: [
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //     ],
    //     paraHeading: "Disney Game Project",
    //     para1:
    //       "The project developed a multiplayer VR game based on the Unreal engine. The project management consists of planning, resources, and execution. The game has been examined and developed to offer a flawless experience to the players and viewers. To make it more alluring it has been designed to work on the animation and sound effects. Based on logic and physics, it is designed in a way that is enjoyable and full of challenges. Moreover, it functions on the user considering the interaction and creates a friendly environment for the child.   ",
    //     para2:
    //       "The game ensures the safety of the child and follows the stipulated relevant regulatory standards. The designed game is supposed to be broadcast live during a Disney children's show. Stipulation of the crucial and innovative technology has been done for live broadcasting of the gameplay of the show.",
    //     slider2: [
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //       {
    //         img: disneyslide2,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Youth Olympics Project",
    //   type: "VR/AR",
    //   img: image8,
    //   productDetails: {
    //     bannerImage: image8,
    //     client: "ENVATO MARKET",
    //     brand: "Youth Olympics",
    //     slider1Image: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //     paraHeading: "Youth Olympics Project",
    //     para1:
    //       "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid.  ",
    //     para2:
    //       "The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
    //     slider2: [
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //       {
    //         img: youth,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
  ],

  automative: [
    {
      name: "Tata Nexon",
      type: "VR/AR",
      img: nexon,
      productDetails: {
        bannerImage: nexon,
        client: "Disney",
        brand: "",
        slider1Image: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
        paraHeading: "Tata Nexon",
        heading1: "Pyjama Ride: An Exciting VR Adventure for Kids",
        heading2: "Project Overview",
        para1:
          "One of our most thrilling projects to date, Pyjama Ride is an immersive Virtual Reality experience designed for a popular children's show. This project showcases our expertise in creating engaging, child-friendly VR content while pushing the boundaries of real-time gameplay and dynamic environments.",
        para2:
          "Pyjama Ride puts young players in control of a sledge racing down a sprawling mountain landscape. This multi-level adventure mimics the excitement of a roller coaster ride, taking children through various thrilling scenarios and unexpected events.",
        impact: "Impact",
        impactberief:
          "Pyjama Ride demonstrates our ability to create engaging, age-appropriate content that sparks imagination in young users. This project stands as a testament to our commitment to innovation and expertise in crafting unforgettable virtual experiences.",

        featureheading: "Key Feature",
        technical: "Technical Achievements",
        technicalsubheading:
          "Our team overcame several challenges to bring this project to life:",
        featurelist: [
          {
            list: "Intuitive controls for young players",
          },
          {
            list: "Multiple levels with varied terrain and challenges",
          },
          {
            list: "Dynamic events (e.g., collapsing rocks, flowing lava)",
          },
          {
            list: "Real-time gameplay recording capabilities",
          },
        ],

        technicallist: [
          {
            head: "Dynamic Track System",
            list: "We developed a system allowing for custom tracks with precise control over sledge speed and track shape.",
          },
          {
            head: "Real-time Recording",
            list: "We implemented a system for capturing the VR gameplay in real-time.",
          },
          {
            head: "Expansive, Seamless World",
            list: "Our developers crafted a vast, multi-level map providing a continuous sliding experience.",
          },
          {
            head: "Dynamic Event Triggering",
            list: "We created a flexible system for triggering real-time events during gameplay.",
          },
          {
            head: "VR Optimization",
            list: "The entire experience was optimized for smooth VR performance.",
          },
        ],
        slider2: [
          {
            img: mahindra,
          },
          {
            img: mahindra1,
          },
          {
            img: mahindra2,
          },
          {
            img: mahindra3,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    // {
    //   name: "Mercedes Maybach Meta 2 Demo",
    //   type: "AUTOMOTIVE",
    //   img: image4,
    //   productDetails: {
    //     bannerImage: image4,
    //     client: "ENVATO MARKET",
    //     brand: "Mercedes ",
    //     slider1Image: [
    //       {
    //         img: mercs,
    //       },
    //       {
    //         img: mercs1,
    //       },
    //       {
    //         img: mercs2,
    //       },
    //       {
    //         img: mercs3,
    //       },
    //     ],
    //     paraHeading: "Mercedes Maybach",
    //     para1:
    //       "Mercedes Maybach Meta 2 demo created a mixed reality (MR) exhibit an Indian automotive show. It enabled visitors to see a Mercedes-Maybach's features in mixed reality by placing virtual elements over the real-world car. Installed a system that lets the user explore the MR cross-sections and characteristics of the car. It aimed to achieve a seamless experience by bringing the virtual and real-world cars into alignment. Also, it developed animations and visual effects to present the car's attributes in an appealing and dynamic manner.  ",
    //     para2:
    //       "It also optimizes the system performance on mixed-reality devices. Mercedes Maybach Meta 2 demo aimed to improve the User Interface's usability &intuitiveness, and tried to replicate as much of the real car's lighting and textures as possible in the virtual model. Generally, project management includes resources, execution and planning.",
    //     slider2: [
    //       {
    //         img: mercs,
    //       },
    //       {
    //         img: mercs1,
    //       },
    //       {
    //         img: mercs2,
    //       },
    //       {
    //         img: mercs3,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Honda Showroom Configurator",
    //   type: "AUTOMOTIVE",
    //   img: image3,
    //   productDetails: {
    //     bannerImage: image3,
    //     client: "ENVATO MARKET",
    //     brand: "Honda Showroom",
    //     slider1Image: [
    //       {
    //         img: inter,
    //       },
    //       {
    //         img: inter1,
    //       },
    //       {
    //         img: inter2,
    //       },
    //       {
    //         img: inter3,
    //       },
    //     ],
    //     paraHeading: "  Honda Showroom ",
    //     para1:
    //       "Honda Showroom Configurator designed a configurator that utilizes a 60-inch display for Honda showrooms. and permitted sales consultants to use their mobile devices to log in and operate the configurator. It provided options for selecting several cars to display their features, colours, and versions, as well as the ability to add or remove accessories.  ",
    //     para2:
    //       "It incorporated 2D and 3D animations that displayed the car's details and gave the user the option to view it from different angles including interior and exterior, as well as in varied lighting conditions including day and night. It improved the system by adding data collecting features that gathered user data and the car configurations they viewed. The customer had the opportunity to review usage metrics as well.",
    //     slider2: [
    //       {
    //         img: inter,
    //       },
    //       {
    //         img: inter1,
    //       },
    //       {
    //         img: inter2,
    //       },
    //       {
    //         img: inter3,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-down",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Mahindra VR Configurator",
    //   type: "AUTOMOTIVE",
    //   img: image1,
    //   productDetails: {
    //     bannerImage: image1,
    //     client: "ENVATO MARKET",
    //     brand: "Mahindra",
    //     slider1Image: [
    //       {
    //         img: mahindra,
    //       },
    //       {
    //         img: mahindra1,
    //       },
    //       {
    //         img: mahindra2,
    //       },
    //       {
    //         img: mahindra3,
    //       },
    //     ],
    //     paraHeading: "Mahindra VR Configurator",
    //     para1:
    //       "Designed a virtual reality automobile configurator with Unreal Engine. enabled users to customize a car in virtual reality by altering colors, models, and accessories. Launched a very thorough 3D model of the car, enabling viewers to explore the outside and inside in virtual reality. As the users will open the door, they may experience the complete model. To make the virtual car seem genuine, work was done on its physics and animation. This user-friendly interface is created to make it simple for users to personalize their virtual reality car.  ",
    //     para2:
    //       "To smooth the performance of VR headsets, the system has been optimized. It enables users to compare, save, and share different setups and also ensures that the final model meets all the standards, for example, accessibility. Data collection was added to the system so that the user's information and the car configuration they browsed were stored. In addition, it gives the client the option to analyze the usage metric. The car could be viewed from various angles by the user.",
    //     slider2: [
    //       {
    //         img: mahindra,
    //       },
    //       {
    //         img: mahindra1,
    //       },
    //       {
    //         img: mahindra2,
    //       },
    //       {
    //         img: mahindra3,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
    // {
    //   name: "Renault Cloud  Configurator",
    //   type: "AUTOMOTIVE",
    //   img: Renault,
    //   productDetails: {
    //     bannerImage: Renault,
    //     client: "ENVATO MARKET",
    //     brand: "Renault",
    //     slider1Image: [
    //       {
    //         img: Renault,
    //       },
    //       {
    //         img: Renault,
    //       },
    //       {
    //         img: Renault,
    //       },
    //       {
    //         img: Renault,
    //       },
    //     ],
    //     paraHeading: "Renault Cloud  Configurator",
    //     para1:
    //       "A cloud rendering-based automobile configurator was developed so that users could view it on their browsers. Renault Online Cloud Streamed Configurator provide a top-notch experience that is accessible on any device, the configurator was built using Unreal Engine, running on rendering servers and streaming to the frontend utilizing WebRTC technology. ",
    //     para2:
    //       "Also, to minimize server costs, a backend system was created to manage several Unreal Engine instances and servers.",
    //     slider2: [
    //       {
    //         img: Renault,
    //       },
    //       {
    //         img: Renault,
    //       },
    //       {
    //         img: Renault,
    //       },
    //       {
    //         img: Renault,
    //       },
    //     ],
    //   },
    //   dataAos: "fade-up",
    //   dataAosOffset: "20",
    //   dataaosdelay: "50",
    //   dataaosduration: "800",
    //   dataaoseasing: "ease-in-out",
    //   dataaosmirror: "false",
    //   dataaosonce: "true",
    //   dataaosanchorplacement: "top",
    // },
  ],

  archite: [
    {
      name: "Reality Scale Interior",
      type: "     ARCHITECTURAL",
      img: image5,
      productDetails: {
        bannerImage: image5,
        client: "ENVATO MARKET",
        brand: "Reality Scale Interior",
        slider1Image: [
          {
            img: image5,
          },
          {
            img: image5,
          },
          {
            img: image5,
          },
          {
            img: image5,
          },
        ],
        paraHeading: "Reality Scale Interior",
        para1:
          "A home surveillance camera that pays great attention to security and user privacy, featuring two modes to provide security while protecting personal privacy. The camera has an open and closed mode, we define the product to have clear two sides, expressing two working states and emotions.",
        para2:
          "Presents a simple and quiet state when not in use, delivering a gentle and security. At the same time, the camera can adapt to a variety of environments, providing elegant ways of wall hanging and standing installation.",
        slider2: [
          {
            img: image5,
          },
          {
            img: image5,
          },
          {
            img: image5,
          },
          {
            img: image5,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Fashion Project",
      type: "ARCHITECTURAL",
      img: image6,
      productDetails: {
        bannerImage: image6,
        client: "ENVATO MARKET",
        brand: "Fashion Project",
        slider1Image: [
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
        ],
        paraHeading: "Simplicity, elegance, innovation",
        para1:
          "A home surveillance camera that pays great attention to security and user privacy, featuring two modes to provide security while protecting personal privacy. The camera has an open and closed mode, we define the product to have clear two sides, expressing two working states and emotions.",
        para2:
          "Presents a simple and quiet state when not in use, delivering a gentle and security. At the same time, the camera can adapt to a variety of environments, providing elegant ways of wall hanging and standing installation.",
        slider2: [
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
        ],
      },
      dataAos: "fade-down",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Fashion Project",
      type: "ARCHITECTURAL",
      img: image6,
      productDetails: {
        bannerImage: image6,
        client: "ENVATO MARKET",
        brand: "Fashion Project",
        slider1Image: [
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
        ],
        paraHeading: "Simplicity, elegance, innovation",
        para1:
          "A home surveillance camera that pays great attention to security and user privacy, featuring two modes to provide security while protecting personal privacy. The camera has an open and closed mode, we define the product to have clear two sides, expressing two working states and emotions.",
        para2:
          "Presents a simple and quiet state when not in use, delivering a gentle and security. At the same time, the camera can adapt to a variety of environments, providing elegant ways of wall hanging and standing installation.",
        slider2: [
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
          {
            img: image6,
          },
        ],
      },
      dataAos: "fade-down",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
  ],
  gaming: [
    {
      name: "Disney Game Project",
      type: "     GAMING",
      img: disneyslide2,
      productDetails: {
        bannerImage: disneyslide2,
        client: "ENVATO MARKET",
        brand: "Disney Game",
        slider1Image: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
        ],
        paraHeading: "Disney Game Project",
        para1:
          "The project developed a multiplayer VR game based on the Unreal engine. The project management consists of planning, resources, and execution. The game has been examined and developed to offer a flawless experience to the players and viewers. To make it more alluring it has been designed to work on the animation and sound effects. Based on logic and physics, it is designed in a way that is enjoyable and full of challenges. Moreover, it functions on the user considering the interaction and creates a friendly environment for the child.   ",
        para2:
          "The game ensures the safety of the child and follows the stipulated relevant regulatory standards. The designed game is supposed to be broadcast live during a Disney children's show. Stipulation of the crucial and innovative technology has been done for live broadcasting of the gameplay of the show.",
        slider2: [
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
          {
            img: disneyslide2,
          },
        ],
      },
      dataAos: "fade-up",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Youth Olympics Project",
      type: "GAMING",
      img: image8,
      productDetails: {
        bannerImage: image8,
        client: "ENVATO MARKET",
        brand: "Youth Olympics",
        slider1Image: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
        paraHeading: "Youth Olympics Project",
        para1:
          "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid.  ",
        para2:
          "The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
        slider2: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
      },
      dataAos: "fade-down",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
    {
      name: "Youth Olympics Project",
      type: "GAMING",
      img: image8,
      productDetails: {
        bannerImage: image8,
        client: "ENVATO MARKET",
        brand: "Youth Olympics",
        slider1Image: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
        paraHeading: "Youth Olympics Project",
        para1:
          "An augmented reality (AR) game has been developed to present it in the Olympics. QR code scanning technology has been integrated into the game so that users can just scan a QR code on the ground to start their AR adventure. This game was developed to be multiplayer in nature to allow multi-users to connect with other players. It is the game where multi-users may ride a virtual bike and move it around a lanes or laps. In order to make AR experience more fascinating, special emphasis on the animation and sound effect has been laid.  ",
        para2:
          "The game has been upgraded for its smooth functioning on mobile devices and it worked on logic and physics to offer realistic experience. It has made sure the game's interface was easy for users to play. Project management involves organizing, implementations, and resources. Integration with additional technologies in case they're required to display the game efficiently on the Olympic field.",
        slider2: [
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
          {
            img: youth,
          },
        ],
      },
      dataAos: "fade-down",
      dataAosOffset: "20",
      dataaosdelay: "50",
      dataaosduration: "800",
      dataaoseasing: "ease-in-out",
      dataaosmirror: "false",
      dataaosonce: "true",
      dataaosanchorplacement: "top",
    },
  ],

  uniquedata: [
    {
      head: unique.head1,
      para: unique.para1,
      img: unique.img1,
    },
    // {
    //   head: unique.head2,
    //   para: unique.para2,
    //   img: unique.img2,
    // },
    // {
    //   head: unique.head3,
    //   para: unique.para3,
    //   img: unique.img3,
    // },
    // {
    //   head: unique.head4,
    //   para: unique.para4,
    //   img: unique.img4,
    // },
  ],
  blogdata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog2,
    },
  ],
  blogsingeldata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog2,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog2,
    },
  ],

  blogdetailsdata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blogbanner,
    },
  ],

  blogdummydetailsdata: [
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
    {
      header: "Technology may 24 2024",
      head: "Branding and Identity Design",
      para: "Our creative agency is a team of professionals focused on helping your brand grow.",
      img: blog1,
    },
  ],

  featureImages: [
    {
      images: featureimage1,
    },
    {
      images: featureimage2,
    },
    {
      images: featureimage3,
    },
    {
      images: featureimage4,
    },
    {
      images: featureimage5,
    },
  ],

  data: [
    {
      label: "Home",
      path: "/",
    },
    {
      label: "Team",
      path: "/team",
    },
    {
      label: "Product",
      path: "/portfolio",
    },
    {
      label: "Contact",
      path: "/contact",
    },
    {
      label: "Blog",
      path: "/commingsoon",
    },
  ],

  menuItems: [
    { text: "Projects", color: "#fff" },
    { href: "/portfolio", text: "VR/AR", color: "grey" },
    { href: "/portfolio", text: "Automotive", color: "grey" },
    { href: "/portfolio", text: "Architectural", color: "grey" },
    { href: "/portfolio", text: "Gaming", color: "grey" },
  ],

  links: [
    { url: "/privacy", text: "Privacy Policy" },
    { url: "/terms", text: "Terms and conditions" },
  ],

  addresses: [
    {
      country: "INDIA",
      address:
        "2nd Floor, D-320, Sector 63, Noida, Uttar Pradesh, 201307 INDIA",
    },
    {
      country: "USA",
      address: "30 N Gould St Ste R Sheridan, WY 82801 USA",
    },
    {
      country: "INDIA",
      address: "D-597, Sector-6, C.D.A, Cuttack, Odisha, Pin-753014",
    },
  ],

  socialIcons: [
    {
      href: "https://www.instagram.com/realityscale/",
      icon: <RiInstagramFill />,
    },
    {
      href: "https://www.youtube.com/channel/UCg0f7WD6d_-9N-wCN93cnPA",
      icon: <FaYoutube />,
    },
    {
      href: "https://x.com/realityscale",
      icon: <FaXTwitter />,
    },
    {
      href: "https://www.facebook.com/realityscale/",
      icon: <FaFacebook />,
    },
    {
      href: "https://www.linkedin.com/company/reality-scale/",
      icon: <FaLinkedin />,
    },
  ],
  address:
    "2nd Floor, D-320, Sector 63 Rd, Sector 63, Noida, Uttar Pradesh, 201307 INDIA",
  address2: "30 N Gould St Ste R, Sheridan, WY 82801 USA",
  address3: "30 N Gould St Ste R, Sheridan, WY 82801 USA",
};
export default data;
