import { Box, Typography } from "@mui/material";
import Aos from "aos";
import React, { useState } from "react";
import "./index.css";
import featureData from "../../assests/data/constant";

const Keyfeature = () => {
  Aos.init();

  const [featureImage, setFeatureImage] = useState(featureData.dataType[2]);

  const [show, setShow] = useState("");
  const onchange = (val) => {
    setFeatureImage(val);
    setShow(val.id);
  };

  return (
    <div className="container-fluid bg-black featuresection_alignment">
      <div
        className="main_featurebanner  container "
        style={{
          backgroundImage: `url(${featureImage.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Box className="feature_bannertext">
          <div
            className="list"
            data-aos="fade-down"
            data-aos-offset="20"
            data-aos-delay="50"
            data-aos-duration="800"
            data-aos-easing="ease-in-out"
            data-aos-mirror="false"
            data-aos-once="false"
            data-aos-anchor-placement="top"
          >
            <div className="item banner_data">
              <div className="content">
                <h1 className="image_heading">
                  {featureImage.heading}
                  {featureImage.head}
                </h1>
                {/* <h3 className="image_subheading">{featureImage.heading2}</h3> */}
                <div className="des mt-3">
                  <span className="fw-bold mx-1 text_size ">
                    {featureImage.title}
                  </span>
                  <span className="text_size">{featureImage.sub_title}</span>
                </div>
                <br />
                <div className="des">
                  <span className="fw-bold mx-1 text_size">
                    {featureImage.title2}
                  </span>
                  <span className="text_size">{featureImage.sub_title2}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="system_screen">
            <div className="imagethumbnail_wrapper">
              <div
                className="row"
                style={{
                  gap: "20px",
                }}
              >
                {featureData &&
                  featureData.dataType.map((val, id) => (
                    <div
                      className="col-sm-2"
                      key={id}
                      style={{ flex: "0 0 auto", cursor: "pointer" }}
                      onClick={() => onchange(val)}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${val.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          border:
                            featureImage === val
                              ? "3px solid #ffd94a"
                              : "1px solid transparent",
                        }}
                        className="thumbnail_imagedata"
                      ></div>
                      <Typography className="featurethumbnail_heading">
                        {val.heading}
                      </Typography>{" "}
                      <Typography className="featurethumbnail_heading">
                        {val.head}
                      </Typography>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="mobile_thumbnailwrapper">
            {featureData &&
              featureData.dataType.map((val, id) => (
                <div key={id} onClick={() => onchange(val)}>
                  <div
                    style={{
                      backgroundImage: `url(${val.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      border:
                        featureImage === val
                          ? "2px solid #ffd94a"
                          : "1px solid transparent",
                    }}
                    className="mobile_thumbnailimage"
                  ></div>
                  <Typography className="featurethumbnail_heading">
                    {val.heading}
                  </Typography>{" "}
                  <Typography className="featurethumbnail_heading">
                    {val.head}
                  </Typography>
                </div>
              ))}
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Keyfeature;
